
























































































































































import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import PageTitle from "@/components/General/PageTitle.vue";
import { Term } from "@/models/term.interface";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { Status } from "@/models/status.interface";
import TermForm from "@/components/Layout/Backoffice/TermForm.vue";
import { StyleCheck } from "@/mixins/style-check";
import DotsMenu from "@/components/General/DotsMenu.vue";
@Component({
  components: { PageTitle, Loader, Empty, TermForm, DotsMenu },
})
export default class Terms extends mixins(Navigation, Permissions, StyleCheck) {
  loader = false;
  loading = false;
  loadingDT = false;
  dialog = false;
  dialogDelete = false;
  defaultTerm: Term = {
    id: undefined,
    num: undefined,
    max_num: undefined,
    type: "",
    status: {
      name: "",
    },
  };
  term: Term & { name?: string } = { ...this.defaultTerm };
  search = "";
  options = {};

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  @Watch("search")
  private async searchTermByText() {
    this.loadingDT = true;
    if (!this.search) {
      this.search = "";
    }

    await this.getTerms(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getTerms(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  private async getTerms(page: number, size: number, type = ""): Promise<void> {
    await this.$store
      .dispatch("terms/getTerms", { page, size, type })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Terms.fetchError.terms"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getTerms(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.search
    );
    this.loader = false;
  }

  private get term_types(): { key: string; value: string }[] {
    const types: { key: string; value: string }[] = [];
    let keys = Object.keys(this.$constants.TERM_TYPES);
    for (const key of keys) {
      types.push({ key: key, value: this.$constants.TERM_TYPES[key] });
    }
    return types;
  }

  private get headers() {
    return [
      {
        text: this.$tc("Terms.headers.range"),
        value: "range",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Terms.headers.type"),
        value: "type",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Terms.headers.status"),
        value: "status",
        align: "center",
        sortable: false,
      },
      ...(this.$perms.check({
        actions: ["D", "U"],
        entities: [this.$constants.PRIVILEGES.TERM],
      })
        ? [
            {
              text: this.$tc("Terms.headers.actions"),
              value: "actions",
              sortable: false,
              align: "center",
            },
          ]
        : []),
    ];
  }

  private get terms(): Term[] {
    let response = this.$store.getters["terms/getTerms"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.terms) {
      return response.terms;
    } else {
      return [];
    }
  }

  private async editTerm(term: Term) {
    this.term = { ...term };
    this.term.status = { ...term.status } as Status;
    this.dialog = true;
  }

  private async deleteTermConfirm(term: Term) {
    this.term = { ...term };
    this.term.name = `${term.num} ${
      term.max_num ? "/ " + term.max_num : ""
    } ${this.$tc("Terms.Type." + term.type?.toLowerCase())}`;
    this.dialogDelete = true;
  }

  private async deleteTerm() {
    this.loading = true;
    await this.$store
      .dispatch("terms/updateTermStatus", {
        id: this.term.id as number,
        status: this.$constants.STATUS.DELETED,
      })
      .then(async () => {
        const Success: Notification = {
          message: this.$tc("Terms.deleteSuccess"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        await this.getTerms(
          this.pagination.page,
          this.pagination.itemsPerPage == -1
            ? this.pagination.totalItems
            : this.pagination.itemsPerPage,
          this.search
        );
        this.loading = false;
        this.closeDelete();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Terms.deleteError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);

        this.loading = false;
      });
  }

  private close() {
    this.dialog = false;
    this.term = Object.assign(this.term, this.defaultTerm);
    this.getTerms(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
  }

  private closeDelete() {
    this.dialogDelete = false;
    this.term = Object.assign(this.term, this.defaultTerm);
  }
}
